<template>
  <div class="create-target-ad">
    <b-card>
      <div class="heading">
        <p>
          Add Target Ads
        </p>
      </div>
      <!-- form -->
      <validation-observer ref="addTargetAd">
        <b-form
          class="mt-2"
          style="
          width: 100%"
          @submit.prevent
        >
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="Target Ads Name"
              label-for="blog-edit-title"
              class="mb-2 campaignLabel"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Target Ads Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="postData.name"
                  placeholder="Please enter Target Ads name here"
                  style="height: 42px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="heading">
              <p>
                Target Audience
              </p>
            </div>
            <b-form-group
              text-bolder
              label="Date and Time"
              label-for="blog-edit-date-time"
              class="mb-2 campaignLabel"
            >
              <validation-provider
                id="blog-edit-date-time"
                v-slot="{ errors }"
                name="Date and Time"
                rules="required"
              >
                <flat-pickr
                  v-model="postData.time"
                  class="form-control"
                  :config="{enableTime: true,disable:[{from:'0000-00-00',to:limit}],dateFormat: 'Y-m-d H:i'}"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              text-bolder
              label="Location"
              label-for="blog-map"
              class="campaignLabel"
            >
              <validation-provider
                id="blog-map"
                name="Location"
              >
                <MapView
                  ref="map-view-id"
                  @circleCenterSetFunc="circleCenterSetFunc"
                  @circleRadiusSetFunc="circleRadiusSetFunc"
                  @devicesGetLatLong="devicesGetLatLong"
                />
                <div class="d-flex justify-content-center center align-items-center mt-1">
                  <b-button
                    variant="primary"
                    :disabled="addLocationSpinner"
                    @click="getAndCountOfTheDevicesByArea()"
                  >
                    <div
                      v-if="addLocationSpinner"
                      class="spinner"
                    >
                      <b-spinner
                        small
                      />
                    </div>
                    Find Devices
                  </b-button>
                </div>
                <br>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="maxTimeLimit"
        @addMediaToCampaign="addMediaToTargetAd"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        @removeMediaFromList="deleteMediaFromTargetAd"
      />
      <div class="d-flex justify-content-center mt-0 ">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewTargetAdsCampaign"
        >
          Review
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="openConfirmationModal"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Create
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelTargetAds"
        >
          Cancel
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BForm, BCol, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
// import _ from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import MapView from '@/components/target-ads/MapView.vue'
import TimeLimitCondition from '@/common/config'

Vue.prototype.moment = moment
export default {
  name: 'CreateTargetAd',
  components: {
    MapView,
    flatPickr,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    AddedMediaToCampaign,
    BButton,
    // BMedia,
    // BImg,
    BannerAdsMedia,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY h:mm a')
    },
  },
  data() {
    return {
      maxTimeLimit: 20,
      TimeLimitCondition,
      spinner: false,
      addLocationSpinner: false,
      clearPolygonSpinner: false,
      postData: {
        name: null,
        time: null,
        location: {},
        radius: 0,
        target_ads_has_media: [],
      },
      devicesPaths: [],
      polygonCoordinates: [],
      addedFilesToCampaign: [],
      currentTimeSpan: '',
      limit: '',
      totalDevices: 0,
      // validation
      required,
    }
  },
  watch: {
    devicesPaths() {
      // this.$emit('devicesMarkerFunc', this.devicesPaths)
      this.$refs['map-view-id'].devicesMarkerFunc(this.devicesPaths)
    },
    'postData.time': function () {
      this.postData.time = moment(this.postData.time).format('YYYY-MM-DD[T]h:mm')
    },
  },
  mounted() {
    this.disabledTimeFunc()
  },
  methods: {
    // async clearPolygonFunc() {
    //   this.clearPolygonSpinner = true
    //   this.devicesPaths = []
    //   // await this.$refs['map-view-id'].removePolygonFunc()
    //   this.clearPolygonSpinner = false
    // },
    openConfirmationModal() {
      this.$refs.addTargetAd.validate().then(async success => {
        if (success) {
          this.$swal({
            title: '<span class="font-weight-bolder">Book Target Ads ?</span>',
            icon: 'warning',
            html:
           `You want to book target ads with ${this.totalDevices} devices`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Save',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonAriaLabel: 'Thumbs down',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
              icon: 'primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.createTargetAds()
            }
          })
        }
      })
    },
    disabledTimeFunc() {
      this.currentTimeSpan = moment(Date.now()).format('YYYY-MM-DD[T]h:mm a')
      this.limit = moment((Date.now() + (TimeLimitCondition.condition * 60 * 60 * 1000))).format('YYYY-MM-DD[T]h:mm a')
    // const limit = (currentTimeSpan + (48 * 60 * 60 * 1000)) | moment
    },
    handleClickForDelete($event) {
      if ($event.vertex) {
        this.$refs.polygon.$polygonObject.getPaths()
          .getAt($event.path)
          .removeAt($event.vertex)
      }
    },
    async createTargetAds() {
      this.spinner = true
      await this.addNewTargetAds()
      this.spinner = false
    },
    async addNewTargetAds() {
      try {
        await this.$axios
          .post('target-ads', this.postData)
        await this.$router.push('/target-ads')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message.toString())
      }
    },
    // checkValidationFunc() {
    //   if (this.totalDevices) {
    //     this.openConfirmationModal()
    //   } else {
    //     this.$swal('Cannot create with Zero Devices')
    //   }
    // },
    async getAndCountOfTheDevicesByArea() {
      this.addLocationSpinner = true
      try {
        const requestBody = { location: this.postData.location, radius: this.postData.radius, time: this.postData.time }
        const res = await this.$axios
          .post('target-ads/get-available-devices', requestBody)
        const { data: resData } = res || {}
        const { data } = resData || {}
        const { deviceArray, count } = data || {}
        this.totalDevices = count
        await this.devicesGetLatLong(deviceArray)
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message.toString())
      }
      this.addLocationSpinner = false
    },
    circleCenterSetFunc(center) {
      this.postData.location = center
    },
    circleRadiusSetFunc(radius) {
      this.postData.radius = Number(radius / 1000)
    },
    devicesGetLatLong(array) {
      this.devicesPaths = []
      const paths = []
      array.forEach(data => {
        paths.push({ lat: data.location.y, lng: data.location.x, name: data.name })
      })
      this.devicesPaths = JSON.parse(JSON.stringify(paths))
      return this.devicesPaths
    },
    cancelTargetAds() {
      this.$router.push('/target-ads')
    },
    updateIdsOfMediaInPayload() {
      this.postData.target_ads_has_media = this.addedFilesToCampaign.map(res => res.id)
    },
    addMediaToTargetAd(media) {
      this.addedFilesToCampaign.push(media)
      this.updateIdsOfMediaInPayload()
    },
    deleteMediaFromTargetAd(index) {
      this.addedFilesToCampaign.splice(index, 1)
      this.updateIdsOfMediaInPayload()
    },
    async reviewTargetAdsCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.create-target-ad{
  #map {
    height: 100%;
  }
  .map-div{
    height: 250px !important;
  }
  .heading{
    font-size: 20px;
    line-height: 44px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat",serif;
    text-align: center;
  }
  .form-control{
    height: 42px !important;
  }
}
</style>
