<template>
  <div class="map-view-com">
    <b-form-input
      id="pac-input"
      placeholder="Search Box"
      type="text"
      @change="changeHandler"
    />
    <div class="position-relative pt-2">
      <div
        id="map"
        class="map-div"
      />
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <b-form-radio-group
        v-model="selectedRadius"
        :options="options"
        class="demo-inline-spacing mb-1"
        value-field="value"
        text-field="text"
      />
    </div>
  </div>

</template>

<script>
import { BFormInput, BFormRadioGroup } from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormRadioGroup,
  },
  data() {
    return {
      marker: [],
      markers: [],
      map: '',
      circle: {},
      selectedRadius: null,
      center: { lat: 31.530443164751656, lng: 74.37880084073794 },
      options: [
        { text: ' 5 Km', value: '5000' },
        { text: '10 Km', value: '10000' },
        { text: '15 Km', value: '15000' },
        { text: '20 Km', value: '20000' },
      ],
    }
  },
  watch: {
    'center.lat': function () {
      this.$emit('circleCenterSetFunc', this.center)
    },
    selectedRadius() {
      this.circle.setMap(null)
      this.circleFunc()
      this.distanceChanged(this.selectedRadius)
      this.$emit('circleRadiusSetFunc', this.selectedRadius)
    },
  },
  mounted() {
    this.initMap()
    this.$emit('circleCenterSetFunc', this.center)
    this.$emit('circleRadiusSetFunc', this.selectedRadius)
  },
  methods: {
    initMap() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: this.center.lat, lng: this.center.lng },
        zoom: 10,
        // minZoom: 10,
        // maxZoom: 13,
        mapTypeId: 'roadmap',
      })
      //   // Create the search box and link it to the UI element.
      const input = document.getElementById('pac-input')
      // eslint-disable-next-line no-undef
      const searchBox = new google.maps.places.SearchBox(input)
      this.map.addListener('bounds_changed', () => {
        searchBox.setBounds(this.map.getBounds())
      })
      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces()

        // For each place, get the icon, name and location.
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds()

        places.forEach(place => {
          if (!place.geometry || !place.geometry.location) {
            console.log('Returned place contains no geometry')
            return
          }
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport)
          } else {
            bounds.extend(place.geometry.location)
          }
          this.center.lat = place.geometry.location.lat()
          this.center.lng = place.geometry.location.lng()
        })
        // this.map.addListener('bounds_changed', () => {
        //   this.map.setZoom(10)
        // })
        this.map.fitBounds(bounds)
        // this.circle.setMap(null)
        // this.drawCircle(0, 0)
        // // eslint-disable-next-line no-undef
        // google.maps.event.addListener(this.circle, 'dragend', () => {
        //   this.center.lat = this.circle.getCenter().lat()
        //   this.center.lng = this.circle.getCenter().lng()
        // })
        // // eslint-disable-next-line no-undef
        // google.maps.event.addListener(this.circle, 'radius_changed', () => {
        //   this.selectedRadius = this.circle.getRadius()
        // })
      })
      this.drawCircle(this.center.lat, this.center.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })
    },
    circleFunc() {
      this.drawCircle(this.center.lat, this.center.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })
    },
    distanceChanged(val) {
      this.circle.set('radius', Number(val))
    },
    changeHandler() {
      this.circle.setMap(null)
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.selectedRadius = 0
    },
    devicesMarkerFunc(devicesPaths) {
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.markers = []
      // eslint-disable-next-line no-undef,no-plusplus
      for (let i = 0; i < devicesPaths.length; i++) {
        // eslint-disable-next-line no-undef
        this.markers.push(new google.maps.Marker({
          position: devicesPaths[i],
          title: devicesPaths[i].name,
        }))
        // this.marker.setMap(this.map)
      }
      this.markers.forEach(marker => {
        marker.setMap(this.map)
      })
    },
    drawCircle(lat, lng) {
      // eslint-disable-next-line no-undef
      this.circle = new google.maps.Circle({
        strokeColor: '#ed1c24',
        strokeOpacity: 0.2,
        strokeWeight: 2,
        fillColor: '#ed1c24',
        fillOpacity: 0.4,
        map: this.map,
        zIndex: 1,
        center: { lat, lng },
        radius: this.selectedRadius,
        draggable: true,
      })
      this.circle.setMap(this.map)
    },
  },
}
</script>

<style lang="scss">
@import "src/assets/scss/style";
.map-view-com {
  #map {
    height: 100%;
  }

  .map-div {
    height: 250px !important;
  }

  /* Optional: Makes the sample page fill the window. */
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
</style>
